html,
body,
#__next {
	width: 100%;
	/* To smooth any scrolling behavior */
	-webkit-overflow-scrolling: touch;
	margin: 0px;
	padding: 0px;
	/* Allows content to fill the viewport and go beyond the bottom */
	min-height: 100%;
}
#__next {
	flex-shrink: 0;
	flex-basis: auto;
	flex-direction: column;
	flex-grow: 1;
	display: flex;
	flex: 1;
}
html {
	scroll-behavior: auto;
	/* Prevent text size change on orientation change https://gist.github.com/tfausak/2222823#file-ios-8-web-app-html-L138 */
	-webkit-text-size-adjust: 100%;
	height: 100%;
}
body {
	display: flex;
	flex: 1;
	/* Pull this from Background in colors.ts */
	background-color: #08090c;
	color: #ffffff;
	/* Allows you to scroll below the viewport; default value is visible */
	overflow-y: auto;
	overscroll-behavior-y: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-overflow-style: scrollbar;
}
.no-scroll {
	overflow: hidden;
}

.pointerEvents-auto {
	pointer-events: auto;
}
.pointerEvents-none {
	pointer-events: none;
}
.pointerEvents-boxNone {
	pointer-events: none !important;
}
.pointerEvents-boxNone > * {
	pointer-events: auto;
}
.pointerEvents-boxOnly {
	pointer-events: auto !important;
}
.pointerEvents-boxOnly > * {
	pointer-events: none;
}

.overrideFocus input:not([type]):focus,
.overrideFocus input[type="text"]:focus {
	outline: none;
}
.overrideFocus textarea:focus {
	outline: none;
}

.focusParent {
	border: solid #00000000 2px; /* Default state, no color */
	transition: border-color 0.1s ease; /* Animate border color on focus */
}
.focusParent:focus-within {
	border-color: #335cff; /* Border color when focused (action colored) */
}

input:not([type]):focus,
input[type="text"]:focus {
	outline: solid #335cff 2px;
}
textarea:focus {
	outline: solid #335cff 2px;
}

a {
	color: inherit;
	text-decoration: none;
}
